@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Noto+Sans+Mono:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p a {
  
  text-decoration: underline;
  transition: all .3s;
}

p a:hover {
  text-decoration: none;
  color: #38bdf8;
}

a svg {
  max-width: 1rem;
  max-height: 1rem;
}